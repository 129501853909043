import React,{ useState, useEffect, useLayoutEffect } from 'react';
import { Marker, useMapEvents, useMap} from 'react-leaflet'

import {firms_cache_location} from '../../../function/map_firms';

import L from "leaflet";

export default function MarkerFirms(prop) {

    const map = useMap();

    //console.log("map", await location_icon(map));
    const [data, setData] = useState<any>([]);
    const [zooms, setZooms] = useState<number>();
  
    async function get_markers(e){
      const mapBounds = e.getBounds();
        const maker_location = await firms_cache_location(mapBounds);
        //console.log("abc", maker_location)
  
        //กันกรณีดึงข้อมูลไม่ทัน เกินปัญหาจาก API ที่ไปดึงไม่ทัน
        if(maker_location){
          setData(maker_location);
        }
    }
  
  
    useMapEvents({
      dragend: async (e) => {
          get_markers(e.target)
          setZooms(e.target.getZoom()*2.5);
      },  
      zoom: async (e) => {
          get_markers(e.target)
          setZooms(e.target.getZoom()*2.5);
      }
    });
  
    //เช็คว่า load ครั้งแรกไหม
    const [firstLoading, setStatusLoading] = useState<boolean>(true);
  
    useLayoutEffect(() => {
      if (firstLoading) {
        setStatusLoading(false);
        return;
      }
  
      //console.log("componentDidUpdateFunction");
    });
  
  
    const get_first_data = async () => {
      if(firstLoading === false){
        
      }else{
        //console.log("first loadingz");
        get_markers(map)
        setZooms(map.getZoom()*2.5);
      }
    }
    
    useEffect(() => {
      get_first_data();
      setZooms(map.getZoom()*2.5);
    }, [prop.use]);
  
    const svgIcon = (zooms) => L.divIcon({
        html:`
        <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
        <svg height='${zooms}' width='${zooms}' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' 
             viewBox='0 0 512 512' xml:space='preserve'>
        <path style='fill:#FFB446;' d='M102.079,350.064c0,85.008,68.913,153.921,153.921,153.921l0,0
            c85.008,0,153.921-68.913,153.921-153.921c0-85.512-42.756-153.921-14.069-213.779c0,0-45.789,8.551-71.443,76.96
            c0,0-85.511-85.511-49.785-205.228c0,0-87.034,34.205-77.932,145.37c7.805,95.325-9.103,111.165-9.103,111.165
            c0-76.96-60.17-111.165-60.17-111.165C144.835,247.449,102.079,273.102,102.079,350.064z'/>
        <path style='fill:#FFDC64;' d='M367.105,386.516c0,64.024-49.435,118.679-113.447,117.445
            c-60.241-1.161-105.062-46.996-100.272-106.867c2.138-26.722,22.447-70.013,55.582-113.837l22.091,13.361
            C230.347,242.46,256,170.489,256,170.489C272.852,246.321,367.105,265.977,367.105,386.516z'/>
        <path style='fill:#FFFFFF;' d='M213.244,332.96c-8.551,51.307-25.653,69.987-25.653,102.614c0,34.205,30.628,68.409,68.409,68.409
            l0,0c38.054,0,68.514-31.092,68.408-69.146c-0.285-102.204-59.857-170.286-59.857-170.286c8.551,51.307-17.102,94.063-17.102,94.063
            C236.764,342.587,213.244,332.96,213.244,332.96z'/>
        <path d='M404.517,253.303c-9.361-43.007-17.445-80.149-1.437-113.554c1.305-2.722,0.97-5.947-0.868-8.344s-4.865-3.556-7.833-3.001
            c-1.928,0.36-44.925,9.065-72.442,69.824c-7.454-9.26-18.778-25.088-28.55-46.086c-21.592-46.4-25.32-94.118-11.081-141.833
            c0.878-2.938,0.001-6.12-2.258-8.195c-2.258-2.075-5.501-2.681-8.357-1.558c-0.959,0.377-23.728,9.498-45.351,33.036
            c-19.896,21.658-42.577,60.136-37.639,120.449c2.624,32.049,2.34,54.5,1.102,69.811c-6.327-21.313-16.799-37.59-26.124-48.854
            c-15.797-19.084-31.632-28.202-32.299-28.58c-2.711-1.54-6.069-1.37-8.61,0.438c-2.54,1.807-3.802,4.924-3.234,7.991
            c9.748,52.657,0.088,82.566-10.14,114.23c-7.539,23.34-15.335,47.476-15.335,80.985C94.063,439.356,166.708,512,256,512
            s161.937-72.644,161.937-161.937C417.937,314.956,410.808,282.202,404.517,253.303z M315.73,483.172
            c10.436-13.068,16.696-29.61,16.696-47.598c0-54.571-16.736-99.498-30.776-127.577c-15.249-30.499-30.427-47.995-31.065-48.724
            c-2.916-3.333-7.98-3.671-11.312-0.755c-3.332,2.916-3.67,7.98-0.755,11.312c0.578,0.662,57.874,67.282,57.874,165.744
            c0,33.3-27.092,60.392-60.392,60.392c-34.565,0-60.392-31.884-60.392-60.392c0-14.131,3.073-23.718,6.08-31.232
            c1.645-4.111-0.355-8.777-4.466-10.422c-4.112-1.645-8.777,0.355-10.421,4.465c-3.364,8.412-7.227,20.238-7.227,37.189
            c0,17.017,6.706,34.367,18.527,48.4c-51.729-22.451-88.005-74.02-88.005-133.911c0-30.983,7.072-52.878,14.559-76.058
            c9.126-28.256,18.526-57.355,13.572-102.402c16.627,14.982,41.347,45.275,41.347,92.949c0,3.195,1.897,6.085,4.829,7.355
            c2.93,1.271,6.336,0.679,8.669-1.505c3.295-3.087,19.341-23.269,11.611-117.669c-3.562-43.492,7.536-79.749,32.981-107.763
            c8.599-9.467,17.49-16.367,24.69-21.101c-11.697,56.341,2.011,103.911,16.496,135.039c17.311,37.2,38.975,59.093,39.889,60.007
            c1.926,1.927,4.701,2.743,7.366,2.167c2.664-0.577,4.852-2.468,5.809-5.02c14.699-39.2,35.878-57.342,50.128-65.525
            c-9.046,33.339-1.312,68.883,6.807,106.179c6.419,29.481,13.054,59.967,13.054,93.349
            C401.904,409.246,366.484,460.308,315.73,483.172z'/>
        <path d='M247.119,344.875c-16.534-19.299-31.955-19.932-33.874-19.932c-4.427,0-8.017,3.589-8.017,8.017
            c0,11.875-1.398,22.004-4.4,31.873c-1.288,4.236,1.102,8.715,5.338,10.003c0.778,0.236,1.563,0.35,2.336,0.35
            c3.436,0,6.614-2.228,7.666-5.687c2.488-8.182,4.049-16.724,4.717-25.888c0.159,0.083,0.321,0.169,0.484,0.258
            c4.735,2.576,11.947,7.995,19.411,19.191c1.332,1.999,3.49,3.294,5.88,3.531c2.395,0.239,4.761-0.61,6.458-2.309
            c1.193-1.193,11.823-12.399,17.595-38.119c0.969-4.32-1.747-8.609-6.067-9.577c-4.317-0.97-8.608,1.747-9.577,6.066
            C252.825,332.65,249.747,339.95,247.119,344.875z'/>
        </svg>`,
        className: "svg-icon-fire",
        iconSize: [zooms, zooms],
    });

  
    return (
      // Make sure you're returning JSX here
      <>
      {prop.use ? data.map((marker: any, index: number) => (
          <Marker
            key={index}
            position={[marker.latitude, marker.longitude]}
            icon={svgIcon(24)}
          >
          </Marker>
        )) : null }
      </>
    );
}