import React from 'react';
import '../css/main.css';


function Rule() {
  return (
    <div className="App">
      History
  </div>

  );
}

export default Rule;
